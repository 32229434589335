import { Component, HostBinding, Input, type OnInit } from '@angular/core';
import { FormConfigElement } from '@big-direkt/form/contracts';
import { BaseComponent } from '../../../base-components/base/base.component';
import { type BigUntypedFormControl } from '../../../form-control/big-form-control';
import { ReplaceTokenRefPipe } from '../../../pipes/replace-token-ref/replace-token-ref.pipe';

@Component({
    selector: 'big-form-markup',
    template: '<div [innerHTML]="settings.markup  | replaceTokenRef:settings.arrayParents"></div>',
    imports: [ReplaceTokenRefPipe],
})
export class MarkupComponent extends BaseComponent implements OnInit {
    private readonly headingClasses = '[&_h3]:mt-20';
    private readonly olClasses = '[&_ol]:list-decimal [&_ol]:list-outside [&_ol]:mb-5 [&_ol]:ml-4';
    private readonly liClasses = '[&_li]:list-item [&_li]:break-long-words [&_li]:mb-4';

    @HostBinding('class') @Input() public override classList = `block not-last:mb-6 ${this.headingClasses} ${this.olClasses} ${this.liClasses}`;

    @Input({ required: true }) public declare settings: FormConfigElement;


    public ngOnInit(): void {
        // necessary for MarkupComponents outside of any page
        if (!(this.control as BigUntypedFormControl | undefined)) {
            this.init();
        }
    }
}
